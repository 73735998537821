<template>
  <v-app>
    <v-row class="d-flex">
      <v-col cols="12" lg="7" xl="6" class="primary d-flex align-center justify-center">
        <v-container>
          <div class="pa-10">
            <v-row justify="center">
              <v-col cols="10" md="8" xl="5">
                <div class="d-flex align-center align-md-start justify-start flex-column">
                  <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                  <div class="tw-hidden md:tw-block">
                    <h6
                      class="tw-hidden md:tw-block subtitle-1 mt-4 white--text op-5 font-weight-regular"
                    >
                      Olá! Você está a poucos passos de contar com a praticidade
                      da nossa ferramenta. Com a opção
                      de integração do Pruvo com a plataforma Google for
                      Education, facilitamos diariamente a vida de milhares de
                      professores, alunos e instituições.
                    </h6>
                    <v-btn class="mt-4 text-capitalize" x-large outlined color="white">Saiba Mais</v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Recuperar senha</h2>
                <h6 class="subtitle-1 mb-5">Digite e confirme sua nova senha</h6>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="form.password"
                    :rules="[rules.password]"
                    label="Senha"
                    dense
                    name="password"
                    required
                    outlined
                    @click:append="() => (show1 = !show1)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  ></v-text-field>

                  <v-text-field
                    v-model="form.passwordConfirm"
                    :rules="passwordRules"
                    label="Confirmar senha"
                    dense
                    name="password"
                    required
                    outlined
                    @click:append="() => (show1 = !show1)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  ></v-text-field>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    outlined
                    @click="submit"
                  >Enviar</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    valid: true,
    show1: false,
    form: {
      email: '',
      password: ''
    },
    rules: {
      password: v => (v && !!v.trim()) || 'A senha é obrigatória.'
    },
    checkbox: false
  }),
  methods: {
    ...mapMutations(['setLoading']),
    async submit () {
      this.$refs.form.validate()
      if (this.$refs.form.validate(true)) {
        //
      }
      // console.log('resetar senha')
    }
  }
}
</script>
<style scoped>
.logo-pruvo {
  max-width: 200px;
}
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}
.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}
.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
